import React from "react"
import ArticleCard from "./article-card"

const ArticlesGrid = ({ edges }) => {
  return (
    <section className="article-grid">
      {edges.map(({ node }) => (
        <ArticleCard node={node} />
      ))}
    </section>
  )
}

export default ArticlesGrid
