import React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ArticleCard = ({ node }) => {
  return (
    <article className="articleItem">
      <div className="articleLeft">
        <Link to={`/articles/${node.url}`}>
          {node.ogpImage ? (
            <img
              src={node.ogpImage}
              alt="ogp"
              className="aside-content-left-picture"
            />
          ) : (
            <StaticImage
              src="../images/nanocms.png"
              alt="topの画像"
              className="aside-content-left-picture"
            />
          )}
        </Link>
      </div>
      <div className="articleRight">
        <Link to={`/articles/${node.url}`}>
          <h2>{node.title}</h2>
        </Link>
        <p className="articleRightSubtitle">{node.description}</p>
        <div className="articleRightDate">
          <span>
            <time>{node.updatedAt}</time>
          </span>
        </div>
      </div>
    </article>
  );
};

export const query = graphql`
  fragment ArticleCard on NanoCMS_ArticleType {
    url
    timestamp
    title
    description
    ogpImage
    category
    content
    updatedAt
  }
`;

export default ArticleCard;
