import React from "react"
import ArticleCard from "./article-card"
import { useStaticQuery, graphql } from "gatsby"

const ArticlesGridNotice = () => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, category: "お知らせ", limit: 2) {
          edges {
            node {
              title
              url
              description
              ogpImage
              updatedAt
              timestamp
            }
          }
        }
      }
    }
  `)
  const edges = nanoCms.articles.edges
  return (
    <section className="article-grid">
      {edges.map(({ node }) => (
        <ArticleCard node={node} />
      ))}
    </section>
  )
}

export default ArticlesGridNotice
