import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlesGrid from "../components/articles-grid";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ArticlesGridNotice from "../components/articles-grid-notice";

export const Head = () => {
  return (
    <Seo description="鎌田鍼灸整骨院は北九州市小倉南区の鍼灸整骨院「体の痛み」「心の痛み」「美容」に関する様々な症状に対応" />
  );
};

const IndexPage = () => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, category: "not お知らせ", limit: 4) {
          edges {
            node {
              title
              url
              description
              ogpImage
              updatedAt
              timestamp
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const navLinks2 = [
    { to: "/", text: "トップ" },
    { to: "/menu", text: "鍼灸メニュー" },
    { to: "/pages", text: "コラム一覧" },
    { to: "#storelist", text: "アクセス・お問い合わせ" },
  ];

  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "鍼灸メニュー",
      link: "/menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "コラム一覧",
      link: "/pages",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
    {
      id: 5,
      name: "アクセス・お問い合わせ",
      link: "/#storelist",
      dropdown: false,
    },
  ];

  return (
    <Layout navLinks={navLinks} color={"rgb(64, 155, 57, 0.2)"}>
      <StaticImage src="../images/topv103.jpg" width={2000} alt="topの画像" />
      <div className="kamata-body">
        <div className="kamata-body-text">
          <div className="kamata-body-text-title">
            <h2>（株）政鍼・鎌田鍼灸整骨院について</h2>
          </div>
        </div>
        <div id="greetings" className="greetingbox">
          <div className="greetingbody">
            <p>
              私どもは、鍼灸・整骨・美容を１ケ所に集約して、利便性を高め、治療から美容まで包括的価値の提供を理念として活動しております。
            </p>
            <p>
              私は、過去に救急病院にてスポーツ整形外科医に師事し、急性外傷への緊急処置や、オペ後の機能回復等を学んだ後に、東洋医学を修めて開業致しました。
            </p>
            <p>
              何が原因か、どのような治療方法があるか、いつ頃改善できるかを明確に説明する方針により、患者様にとって最適最速な治療法を提案いたします。
            </p>
          </div>
          <div className="greetingprofile">
            <div className="greetingicon">
              <StaticImage
                src="../images/代表紹介用アイコン.jpg"
                width={360}
                height={360}
                alt="topの画像"
              />
            </div>
            <div className="greetingName">
              <p>(株）政 鍼　代表取締役</p>
              <p>鎌田鍼灸整骨院</p>
              <div className="name">
                <small>院 長</small>　鎌田 政之
              </div>
            </div>
          </div>
        </div>

        <div className="kamata-body-text">
          <div className="kamata-body-text-title">
            <h2>鎌田鍼灸整骨院の診療時間</h2>
          </div>
        </div>
        <div id="ConsultationTime" className="consultationtime">
          <table className="table-schedule">
            <tr>
              <th></th>
              <th>月</th>
              <th>火</th>
              <th>水</th>
              <th>木</th>
              <th>金</th>
              <th className="saturday">土</th>
              <th className="sunday">日</th>
            </tr>
            <tr>
              <td>9:00～12:00</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
            </tr>
            <tr>
              <td>14:30～20:00</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td className="closed">休</td>
              <td className="closed">休</td>
            </tr>
          </table>
        </div>
        <div className="kamata-body-text">
          <p>
            ※ 第1・第3日曜日、祝祭日の午後は<strong>休診</strong>
          </p>
          <p>住所：小倉南区守恒本町2丁目2-26明夏ビル１階</p>
          <p>
            電話番号：<strong>093-961-8148</strong>
          </p>
        </div>
        <div className="kamata-body-text">
          <div className="kamata-body-text-title">
            <h2>お知らせ</h2>
          </div>
        </div>

        <main>
          <div class="cntWrapper">
            <div class="cntWrapperIndex">
              <div class="mainnavIndex">
                <ArticlesGridNotice />
              </div>
            </div>
          </div>

          <div id="about" className="aboutusbox">
            <h2>鍼灸治療について</h2>
            <div className="aboutbody">
              鎌田鍼灸整骨院では、「体の痛み」「心の悩み」「美容」に関する様々な症状に対するメニューをご用意しております。
              <div className="aboutbody-text">
                <Link to="/menu" className="menu-button">
                  鍼灸治療のメニューはこちら
                </Link>
              </div>
              こんな悩みはありませんか？
              <div className="indexMenu">
                <div className="karada-menu">
                  <Link to="/menu#「体の痛み」">
                    <StaticImage
                      className="image-container"
                      src="../images/体の悩み.png"
                      width={130}
                      height={130}
                      alt="体の痛み"
                    />
                    <p>体の痛み</p>
                  </Link>
                </div>

                <div className="kokoro-menu">
                  <Link to="/menu#「心の悩み」">
                    <StaticImage
                      className="image-container kokoro-border"
                      src="../images/心の悩み.png"
                      width={130}
                      height={130}
                      alt="心の悩み"
                    />
                    <p>心の悩み</p>
                  </Link>
                </div>
                <div className="biyou-menu">
                  <Link to="/menu#「美容」">
                    <StaticImage
                      className="image-container biyou-border"
                      src="../images/美容、その他.png"
                      width={130}
                      height={130}
                      alt="美容、その他"
                    />
                    <p>
                      美 容
                      <br />
                      その他
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div id="about2" className="aboutusbox">
            <h2>整骨治療について</h2>
            <div className="aboutbody">
              <div className="menu-grid">
                <p>
                  鎌田鍼灸整骨院では、腰や肩の悩みにアプローチする整骨治療も行なっております。
                </p>
                <table className="table-menu">
                  <thead>
                    <tr>
                      <th>初診</th>
                      <th>
                        2回目
                        <br />
                        以降
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>3,000</td>
                      <td>1,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="kamata-body-text">
            <div id="column" className="kamata-body-text-title">
              <h2>関連コラム</h2>
            </div>
          </div>

          <div class="cntWrapper">
            <div class="cntWrapperIndex">
              <div class="mainnavIndex">
                <ArticlesGrid edges={nanoCms.articles.edges} />
              </div>
            </div>
          </div>

          <div className="kamata-body-text">
            <div className="kamata-body-text-title">
              <h2>鎌田鍼灸整骨院についてはこちらもチェック！</h2>
            </div>
          </div>
          <div id="about3" className="aboutusbox">
            <div className="aboutbody">
              <div className="link-grid">
                <div className="image-link-ekiten">
                  <StaticImage
                    className="image-container"
                    src="../images/エキテン.png"
                    width={100}
                    height={100}
                    alt="体の痛み"
                  />
                  <a href="https://www.ekiten.jp/shop_4975603/">
                    エキテン ・口コミサイト
                  </a>
                </div>
                <div className="image-link">
                  <StaticImage
                    className="image-container"
                    src="../images/facebook.png"
                    width={60}
                    height={60}
                    alt="体の痛み"
                  />
                  <a href="https://www.facebook.com/people/鎌田政之/pfbid02LsEbjJQsFJMgpspxwmtnTtFm6cP5fd41kywCUcxHSjgUForJvXMS2Cbp154ZQQgNl/">
                    Facebook
                  </a>
                </div>
                <div className="image-link">
                  <StaticImage
                    className="image-container"
                    src="../images/instagram.png"
                    width={60}
                    height={60}
                    alt="体の痛み"
                  />
                  <a href="https://www.instagram.com/1116kamata/?hl=ja">
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="kamata-body-text">
            <div className="kamata-body-text-title">
              <h2>政鍼グループのご紹介</h2>
            </div>
          </div>
          <div id="about2" className="aboutusbox">
            <div className="aboutbody">
              <p>
                株式会社
                政鍼は【鎌田鍼灸整骨院】【守恒鍼灸美容館】【鍼灸エステ一花堂】の３店舗で美容と健康に特化したグループ会社です。
              </p>
              <p>
                治療から美容まで包括的価値を提供する技術集団であり続けることを理念として活動しています。
              </p>
              <p>身体の痛みから髪の悩みまで、何なりとご相談ください。</p>
            </div>
          </div>
          <div id="storelist" className="kamata-body-text">
            <div className="kamata-body-text-title">
              <h2>アクセス・お問い合わせ</h2>
            </div>
          </div>

          <div className="aboutusbox lastbox">
            <div className="access-grid">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13256.1529373776!2d130.8742589!3d33.8371246!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4f78cdbebf6a5ba5!2z6Y6M55Sw6Y2854G45pW06aqo6Zmi!5e0!3m2!1sja!2sjp!4v1670132110751!5m2!1sja!2sjp"
                width="1080"
                height="200"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <Link to="/">
                <StaticImage
                  className="kamata-banner"
                  src="../images/鎌田バナー.png"
                  width={336}
                  height={119}
                  alt="topの画像"
                />
              </Link>
              <Link to="/biyoukan">
                <StaticImage
                  className="biyoukan-banner"
                  src="../images/美容館バナー.png"
                  width={336}
                  height={119}
                  alt="topの画像"
                />
              </Link>
              <Link to="/ikkadou">
                <StaticImage
                  className="ikkadou-banner"
                  src="../images/一花堂バナー.png"
                  width={336}
                  height={119}
                  alt="top"
                />
              </Link>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default IndexPage;
